<template>
  <div class="single-order" v-if="init">
    <a @click="$router.go(-1)" class="back-link">
      <span class="material-icons">arrow_back</span>
      <span class="text">Back</span>
    </a>
    <h1>Order #{{ order.vismaId }}</h1>

    <div class="offline-order" v-if="order.vismaDown === true">
      <div class="inner" v-if="order.vismaId.toString().length > 10">
        <h3>This order was placed while Visma was down</h3>
        <p>
          Make sure below instructions are followed before clicking the resolve
          button.
        </p>
        <ol>
          <li>Manually create order in Visma.</li>
          <li>
            Make sure Visma is running again (check order sync in System tab)
          </li>
          <li>Click resolve button</li>
        </ol>
        <button @click="resolveOrder(order.id, false)">Resolve</button>
      </div>
      <div class="inner" v-else>
        <h3>
          This order recieved a
          <span style="color: red; font-weight: 900">completion</span> while
          Visma was down.
        </h3>
        <p>
          Make sure below instructions are followed before clicking the resolve
          button.
        </p>
        <ol>
          <li>
            Manually update order in Visma to match below products and
            quantities.
          </li>
          <li>
            Make sure Visma is running again (check order sync in System tab)
          </li>
          <li>Click resolve button</li>
        </ol>
        <button @click="resolveOrder(order.id, true)">Resolve</button>
      </div>
    </div>

    <div class="single-order-container">
      <div class="single-order-column-left">
        <div class="table-header-small">Meta</div>
        <div class="admin-table horizontal single-order-meta-table">
          <div>
            <span class="key">firebaseId</span>
            <span class="value">{{ order.id }}</span>
          </div>

          <div>
            <span class="key">vismaId</span>
            <span class="value" v-if="order.vismaId.toString().length > 10"
              >none</span
            >
            <span class="value" v-else>{{ order.vismaId }}</span>
          </div>

          <div>
            <span class="key">createdAt</span>
            <span class="value">{{
              order.createdAt.toDate().getFullYear() +
              "-" +
              ("0" + (order.createdAt.toDate().getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + order.createdAt.toDate().getDate()).slice(-2) +
              " - " +
              ("0" + order.createdAt.toDate().getHours()).slice(-2) +
              ":" +
              ("0" + order.createdAt.toDate().getMinutes()).slice(-2)
            }}</span>
          </div>

          <div>
            <span class="key">createdBy</span>
            <span class="value">{{ getUserEmailById(order.user) }}</span>
          </div>

          <div>
            <span class="key">deliveryDate</span>
            <span class="value">{{
              order.deliveryDate.toDate().getFullYear() +
              "-" +
              ("0" + (order.deliveryDate.toDate().getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + order.deliveryDate.toDate().getDate()).slice(-2)
            }}</span>
          </div>

          <div>
            <span class="key">dispatchDate</span>
            <span class="value" v-if="order.dispatchDate">{{
              order.dispatchDate.toDate().getFullYear() +
              "-" +
              ("0" + (order.dispatchDate.toDate().getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + order.dispatchDate.toDate().getDate()).slice(-2)
            }}</span>
          </div>

          <div>
            <span class="key">company</span>
            <span class="value">{{
              getCompanyNameByVismaId(order.company)
            }}</span>
          </div>

          <div>
            <span class="key">orderNote</span>
            <span class="value">{{ order.orderNote }}</span>
          </div>
        </div>
      </div>
      <div class="single-order-column-right">
        <div class="table-header-small">Products</div>
        <div class="admin-table single-order-products-table">
          <div class="table-header">
            <span class="vismaid label">VismaID</span>
            <span class="quantity label">Quantity</span>
            <span class="product label">Product</span>

            <span class="note label">Note</span>
          </div>

          <div v-for="item in order.orderItems" :key="item.vismaId">
            <span class="vismaid">{{ item.vismaId }}</span>
            <span class="quantity">{{ item.count }}</span>
            <span class="product">{{ item.name }}</span>
            <span class="note">{{ item.note }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SingleOrder",
  data() {
    return {
      init: false,
    };
  },
  methods: {
    navigateToCompany: function (id) {
      if (!id) {
        return;
      }
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
    resolveOrder: function (id, completion) {
      let confirm = window.confirm(
        "Marking offline order as resolved. Are you sure?"
      );
      if (confirm) {
        this.$store
          .dispatch("orders/resolveOfflineOrder", {
            id: id,
            completion: completion,
          })
          .then(() => {
            this.$toast.center("Offline order resolved");
            this.$router.go(-1);
          });
      }
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.orders.singleOrder,
    }),
    ...mapGetters("settings", ["userRoles", "getCompanyNameByVismaId"]),
    ...mapGetters("users", ["getUserEmailById"]),
  },
  created() {
    this.$store
      .dispatch("orders/bindSingleOrder", { id: this.$route.params.id })
      .then(() => {
        this.$nextTick(() => {
          console.log(this.order);
          this.init = true;
        });
      });
  },
};
</script>
